import AppLayout from "@components/Layout/AppLayout";
import Head from "@components/Commons/Head";
import { QdDashboard } from "@components/Dashboards/QdDashboard/QdDashboard";
import { DashboardPageInterface } from "interface/DashboardPageInterface";
import { DEPLOY_ENVIRONMENT } from "constants/constants";

function Dashboard({}: DashboardPageInterface) {
  return (
    <div className="min-h-screen">
      <Head title="Realtime Dashboard – Tracify AI" />
      <QdDashboard />
    </div>
  );
}

Dashboard.Layout = AppLayout;

export async function getStaticProps() {
  return {
    props: {
      approvals:
        DEPLOY_ENVIRONMENT === "beta"
          ? ["access_rts_dashboard", "access_beta_environment"]
          : ["access_rts_dashboard"],
    },
  };
}

export default Dashboard;
